// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-search-site-index-tsx": () => import("./../../../src/pages/search/site/index.tsx" /* webpackChunkName: "component---src-pages-search-site-index-tsx" */),
  "component---src-pages-search-site-search-results-page-index-tsx": () => import("./../../../src/pages/search/site/SearchResultsPage/index.tsx" /* webpackChunkName: "component---src-pages-search-site-search-results-page-index-tsx" */),
  "component---src-templates-article-details-tsx": () => import("./../../../src/templates/articleDetails.tsx" /* webpackChunkName: "component---src-templates-article-details-tsx" */),
  "component---src-templates-article-page-tsx": () => import("./../../../src/templates/articlePage.tsx" /* webpackChunkName: "component---src-templates-article-page-tsx" */),
  "component---src-templates-page-builder-tsx": () => import("./../../../src/templates/pageBuilder.tsx" /* webpackChunkName: "component---src-templates-page-builder-tsx" */),
  "component---src-templates-panel-page-tsx": () => import("./../../../src/templates/panelPage.tsx" /* webpackChunkName: "component---src-templates-panel-page-tsx" */),
  "component---src-templates-product-details-tsx": () => import("./../../../src/templates/productDetails.tsx" /* webpackChunkName: "component---src-templates-product-details-tsx" */),
  "component---src-templates-where-to-buy-tsx": () => import("./../../../src/templates/whereToBuy.tsx" /* webpackChunkName: "component---src-templates-where-to-buy-tsx" */)
}

